import { useForm } from "@formspree/react";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import OnclickButton from "./OnclickButton";

function MultiStepForm2({ colour }) {
	const [state, handleSubmit] = useForm("xwpkynja");
	const [activeStep, setActiveStep] = useState(1);
	const [formValues, setFormValues] = useState({
		name: "",
		email: "",
		phone: "",
		companyName: "",
		services: {
			cleaning: false,
			security: false,
			building: false,
			industrial: false,
		},
		message: "",
		hearAboutUs: "",
	});
	const [formErrors, setFormErrors] = useState({});

	const validateStep = () => {
		let errors = {};
		if (activeStep === 1) {
			if (!formValues.name) errors.name = "Full Name is required";
			if (!formValues.email) errors.email = "Email Address is required";
			if (!formValues.phone) errors.phone = "Mobile Number is required";
		} else if (activeStep === 3) {
			if (!formValues.message) errors.message = "Message is required";
			if (!formValues.hearAboutUs)
				errors.hearAboutUs = "Please select where you heard about us";
		}
		setFormErrors(errors);
		return Object.keys(errors).length === 0;
	};

	const handleInputChange = (e) => {
		const { name, value, type, checked } = e.target;
		if (
			name === "cleaning" ||
			name === "security" ||
			name === "building" ||
			name === "industrial"
		) {
			setFormValues({
				...formValues,
				services: {
					...formValues.services,
					[name]: checked,
				},
			});
		} else {
			setFormValues({ ...formValues, [name]: value });
		}
	};

	const handleNextStep = () => {
		if (validateStep()) {
			setActiveStep(activeStep + 1);
		}
	};

	const handleFormSubmit = (event) => {
		event.preventDefault();
		if (!validateStep()) return;
		handleSubmit(formValues);
	};

	// Update active step to 4 when form is successfully submitted
	useEffect(() => {
		if (state.succeeded) {
			setActiveStep(4);
		}
	}, [state.succeeded]);

	const styles = {
		stepIndicator: {
			marginBottom: "1rem",
		},
		steps: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		step: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		},
		circle: (isActive) => ({
			width: "30px",
			height: "30px",
			borderRadius: "50%",
			border: `1px solid ${colour}`,
			backgroundColor: isActive ? colour : "white",
			lineHeight: "30px",
			textAlign: "center",
			color: isActive ? "white" : "#00304B",
		}),
		line: (isActive) => ({
			flexGrow: 1,
			height: "1px",
			backgroundColor: colour,
			margin: "0 0px",
		}),
		label: {
			marginTop: "5px",
			color: "#fff",
		},
		errorText: {
			color: "red",
			fontSize: "0.875rem",
		},
	};

	const inputStyles = {
		border: "none",
		borderBottom: `1px solid #CEE4FF`,
		borderRadius: 0,
		padding: "10px",
		fontSize: "16px",
		color: "#333",
	};
	const emailInputStyles = {
		border: "none",
		borderBottom: "1px solid #CEE4FF",
		borderRadius: 0,
		padding: "10px",
		fontSize: "16px",
		color: "#333",
	};

	const textareaStyles = {
		border: "1px solid #CEE4FF",
		borderRadius: "0px",
		padding: "10px",
		fontSize: "16px",
		resize: "none",
	};

	// If form is successfully submitted, show the thank you step (Step 4)
	const isSubmitted = state.succeeded;

	return (
		<section id="multi-form" className="py-5 py-lg-7">
			<Container>
				<Row className="justify-content-center">
					<Col md={10} lg={7} xl={6}>
						<div style={styles.stepIndicator}>
							<div style={styles.steps}>
								<div style={styles.step}>
									<div style={styles.circle(activeStep >= 1)}>1</div>
								</div>
								<div style={styles.line(activeStep > 1)}></div>
								<div style={styles.step}>
									<div style={styles.circle(activeStep >= 2)}>2</div>
								</div>
								<div style={styles.line(activeStep > 2)}></div>
								<div style={styles.step}>
									<div style={styles.circle(activeStep >= 3)}>3</div>
								</div>
								<div style={styles.line(activeStep > 3)}></div>
								<div style={styles.step}>
									<div style={styles.circle(activeStep >= 4)}>4</div>
								</div>
							</div>
						</div>
					</Col>
				</Row>
				<Row className="justify-content-center">
					<Col md={9} lg={6} xl={5}>
						{!isSubmitted && activeStep === 1 && (
							<div className="text-center mt-4 mb-4">
								<h2 className="text-primary">
									Let’s begin with your Name and Email
								</h2>
							</div>
						)}
						{!isSubmitted && activeStep === 2 && (
							<div className="text-center mt-4 mb-4">
								<h2 className="text-primary">Who do you represent?</h2>
							</div>
						)}
						{!isSubmitted && activeStep === 3 && (
							<div className="text-center mt-4 mb-5">
								<h2 className="text-primary">What do you need?</h2>
							</div>
						)}
						{isSubmitted && (
							<div className="text-center mt-4 mb-3">
								<h2 className="text-primary">Thank you for your message.</h2>
							</div>
						)}

						<Form className="w-100" onSubmit={handleFormSubmit}>
							{!isSubmitted && (
								<>
									{activeStep === 1 && (
										<>
											<Form.Group className="mb-4" controlId="name">
												<Form.Label className="">*Enter your name:</Form.Label>
												<Form.Control
													style={inputStyles}
													name="name"
													className="special-placeholder"
													placeholder="Full name"
													type="text"
													value={formValues.name}
													onChange={handleInputChange}
													required
												/>
												{formErrors.name && (
													<span style={styles.errorText}>
														{formErrors.name}
													</span>
												)}
											</Form.Group>

											<Form.Group className="mb-4" controlId="email">
												<Form.Label className="">*Enter your email:</Form.Label>
												<Form.Control
													style={emailInputStyles}
													name="email"
													className="special-placeholder"
													placeholder="email@example.com"
													type="email"
													value={formValues.email}
													onChange={handleInputChange}
													required
												/>
												{formErrors.email && (
													<span style={styles.errorText}>
														{formErrors.email}
													</span>
												)}
											</Form.Group>

											<Form.Group className="mb-4" controlId="phone">
												<Form.Label className="">
													*Enter your phone number:
												</Form.Label>
												<Form.Control
													style={emailInputStyles}
													name="phone"
													className="special-placeholder"
													placeholder="Phone number"
													type="tel"
													value={formValues.phone}
													onChange={handleInputChange}
													required
												/>
												{formErrors.phone && (
													<span style={styles.errorText}>
														{formErrors.phone}
													</span>
												)}
											</Form.Group>

											<div className="text-center">
												<OnclickButton
													width="w-100 w-md-auto"
													colour={colour}
													text="Next"
													isOutline={false}
													onClick={handleNextStep}
												/>
											</div>
										</>
									)}

									{activeStep === 2 && (
										<>
											<Form.Group className="mb-4" controlId="companyName">
												<Form.Label className="">
													Enter your company name:
												</Form.Label>
												<Form.Control
													style={inputStyles}
													className="special-placeholder"
													name="companyName"
													placeholder="Company name"
													type="text"
													value={formValues.companyName}
													onChange={handleInputChange}
												/>
											</Form.Group>

											<Form.Group className="mb-4">
												<Form.Label className="mb-2">
													Looking for (tick all that apply):
												</Form.Label>
												<Form.Check
													type="checkbox"
													label="Cleaning Services"
													name="cleaning"
													checked={formValues.services.cleaning}
													onChange={handleInputChange}
												/>
												<Form.Check
													type="checkbox"
													label="Security Services"
													name="security"
													checked={formValues.services.security}
													onChange={handleInputChange}
												/>
												<Form.Check
													type="checkbox"
													label="Building and Maintenance Services"
													name="building"
													checked={formValues.services.building}
													onChange={handleInputChange}
												/>
												<Form.Check
													type="checkbox"
													label="Industrial Cleaning Services"
													name="industrial"
													checked={formValues.services.industrial}
													onChange={handleInputChange}
												/>
											</Form.Group>

											<div className="text-center">
												<OnclickButton
													width="w-100 w-md-auto"
													colour={colour}
													text="Next"
													isOutline={false}
													onClick={handleNextStep}
												/>
											</div>
										</>
									)}

									{activeStep === 3 && (
										<>
											<Form.Group className="mb-4" controlId="message">
												<Form.Control
													style={textareaStyles}
													name="message"
													placeholder="Write your message here"
													as="textarea"
													rows={4}
													value={formValues.message}
													onChange={handleInputChange}
													required
												/>
												{formErrors.message && (
													<span style={styles.errorText}>
														{formErrors.message}
													</span>
												)}
											</Form.Group>
											<Form.Group className="mb-4" controlId="hearAboutUs">
												<Form.Label className="">
													*Where did you hear about us?
												</Form.Label>
												<Form.Control
													className="calibri"
													as="select"
													name="Where did you hear about us" // Set name for form submission
													value={formValues.hearAboutUs || ""} // Link to state
													onChange={
														(e) =>
															setFormValues({
																...formValues,
																hearAboutUs: e.target.value,
															}) // Update state
													}
													required
													style={inputStyles}
												>
													<option value="" disabled>
														Select an option
													</option>
													<option value="Social Media">
														Social Media (LinkedIn, Facebook, Instagram, etc.)
													</option>
													<option value="Recommendation/Word of Mouth">
														Recommendation/Word of Mouth
													</option>
													<option value="Existing Client/Partner">
														Existing Client/Partner
													</option>
													<option value="Industry Event/Trade Show">
														Industry Event/Trade Show
													</option>
													<option value="Email Marketing">
														Email Marketing
													</option>
													<option value="Advertisement">
														Advertisement (Online/Print)
													</option>
													<option value="Job Board/Recruitment Site">
														Job Board/Recruitment Site
													</option>
													<option value="Local Directory/Listing">
														Local Directory/Listing
													</option>
													<option value="Saw Your Van/Branding">
														Saw Your Van/Branding
													</option>
												</Form.Control>
												{formErrors.hearAboutUs && (
													<span style={styles.errorText}>
														{formErrors.hearAboutUs}
													</span>
												)}
											</Form.Group>

											<div className="text-center">
												<OnclickButton
													width="w-100 w-md-auto"
													colour={colour}
													text="Submit"
													isOutline={false}
													type="submit"
													disabled={state.submitting}
												/>
											</div>
										</>
									)}
								</>
							)}

							{isSubmitted && (
								<div className="text-center mt-4">
									<p>
										Thanks for getting in touch. A member of the team will be in
										touch to arrange a callback, so keep an eye on your inbox.
									</p>
								</div>
							)}
						</Form>
					</Col>
				</Row>
			</Container>
		</section>
	);
}

export default MultiStepForm2;
